/**
 *
 * Converts json array to to csv format.
 *
 * @param {any[]} items array of objects
 * @param {string} filename the name of the file which would be downloaded
 * @param {boolean} [download=true] download the csv file with {filename} along with returning csv string
 * @returns {string} csv string
 */
const json_to_csv = (items: any[], filename: string, download = true) => {
  // @ts-expect-error
  const replacer = (key: any, value: any) =>
    typeof value === "string" ? value : JSON.stringify(value); // specify how you want to handle null values here
  const header = Object.keys(items[0]).sort();
  const csv = [
    header.join(","), // header row first
    ...items.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");

  if (!download) {
    return csv;
  } else {
    const blob = new Blob([csv], { type: "text/csv" });
    // @ts-expect-error
    if (window.navigator?.msSaveOrOpenBlob) {
      // @ts-expect-error
      window.navigator?.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
    return csv;
  }
};

export default json_to_csv;
